<template>
  <span v-if="ds.sortColumn === name && ds.sortDirect === 1" @click="sortBy">
    <slot></slot> <span class="arrow">&#9650;</span>
  </span>

  <span v-else-if="ds.sortColumn === name && ds.sortDirect === -1" @click="sortBy">
    <slot></slot> <span class="arrow">&#9660;</span>
  </span>

  <span v-else @click="sortBy">
    <slot></slot> <span class="arrow transparent">&#9660;</span>
  </span>
</template>

<script>
export default {
  name: "SortIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    ds: {
      type: Object,
      required: true,
    }
  },
  methods: {
    sortBy() {
      // eslint-disable-next-line vue/no-mutating-props
      this.ds.sortBy(this.name);
    }
  }
}
</script>

<style scoped>
.arrow {
  font-size: 80%;
}
.transparent {
  opacity: 0; /* reserve space width for arrow */
}
</style>