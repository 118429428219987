<template>
  <a-input size="large" v-bind="$attrs"></a-input>
</template>

<script>
export default {
  name: "UiTextInput",
  inheritAttrs: false,
}
</script>

<style lang="scss"></style>
