<template>
  <div class="bar-slider">
    <div class="bar-slides" :style="slidesStyles">

      <div class="bar-slide">

        <template v-if="student.isRejected">
          <UiButton class="btn-blue" @click="$emit('pushStatus', {
            status: student.prevStatusName,
            comment: '',
          })">Move to {{student.prevStatusTitle}}</UiButton>
        </template>
        <template v-else>
          <UiButton class="btn-red" @click="showComment">{{$t('ButtonBar.decline')}}</UiButton>
        </template>

        <UiButton class="btn-blue"
                  v-if="student.tempStatusName === 'review_started'"
                  @click="$emit('pushStatus', {
          status:'interview_booking',
          comment: '',
        })">{{$t('ButtonBar.move_to_interview')}}
        </UiButton>

        <UiButton class="btn-blue"
                  v-if="['interview_booking',
                    'interview_booked',
                    'interview_attended'].includes(student.tempStatusName)"
                  :disabled="student.tempStatusName !== 'interview_attended'"
                  @click="$emit('pushStatus', {
          status:'choosing_payment',
          comment: '',
        })">{{$t('ButtonBar.move_to_payment')}}
        </UiButton>

        <UiButton class="btn-blue"
                  v-if="[
                      'choosing_payment',
                      'payment_by_card',
                      'payment_by_wire_transfer',
                      'payment_confirming'].includes(student.tempStatusName)"
                  :disabled="student?.payment?.requiredAmount > student?.payment?.paidAmount"
                  @click="$emit('pushStatus', {
          status:'payment_completed',
          comment: '',
        })">{{$t('ButtonBar.move_to_enrollment')}}
        </UiButton>

        <UiButton class="btn-blue"
                  v-if="['enrolled', 'payment_completed'].includes(student.tempStatusName)"
                  :disabled="student.tempStatusName == '!payment_confirming'"
                  @click="$emit('pushStatus', {
          status:'enrolled',
          comment: '',
        })">{{$t('ButtonBar.move_to_students')}}
        </UiButton>




      </div>

      <div class="bar-slide">
        <div v-if="student.panelName == 'cancelSave'">
          <UiButton class="btn-white" @click="closeCancelSave">
            {{$t('ButtonBar.cancel')}}
          </UiButton>
          <UiButton class="btn-blue" @click="onSave">
            {{$t('ButtonBar.save')}}
          </UiButton>
        </div>

        <div v-if="student.panelName == 'comment'" class="reject-reason">
          <UiRejectReason :value="comment"  :status="student.tempStatusName" @change="comment = $event"></UiRejectReason>
          <UiButtonOk class="reject-reason-ok"
                      v-if="comment"
                      @click="$emit('pushStatus', {
                        status: 'interview_rejected',
                        comment: comment,
                        })">
          </UiButtonOk>
          <UiButtonClose @click="closeComment"></UiButtonClose>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import UiButton from "@/components/ui/UiButton";
import UiRejectReason from "@/components/ui/UiRejectReason";
import UiButtonOk from "@/components/ui/UiButtonOk";
import UiButtonClose from "@/components/ui/UiButtonClose";

export default {
  name: "ButtonBar",
  components: {UiRejectReason, UiButton, UiButtonClose, UiButtonOk},
  emits: ['pushStatus', 'save'],
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comment: null,
    }
  },
  computed: {
    slidesStyles() {
      const offset = this.student.showPanel ? -50 : 0;
      return {transform: 'translateY(' + offset + '%)'}
    },
    isChanged() {
      return this.student.isChanged;
    },
    // declineStatus() {
    //   if () return 'review_rejected';
    //   if () return 'interview_rejected';
    // }
  },
  methods: {
    onSave() {
      this.student.onSave();
      this.student.showPanel = false;
    },
    showCancelSave() {
      this.student.panelName = 'cancelSave';
      setTimeout(() => {
        this.student.showPanel = true;
      }, 0)
    },
    closeCancelSave() {
      this.student.showPanel = false;
      this.student.onCancel();
    },

    showComment() {
      this.student.panelName = 'comment';
      setTimeout(() => {
        this.student.showPanel = true;
      }, 0)
    },
    closeComment() {
      if (this.comment) {
        this.comment = null;
        return;
      }
      this.student.showPanel = false
    }
  },

  watch: {
    isChanged(newValue) {
      console.log(newValue);
      if (newValue) {
        this.showCancelSave();
      }
    }
  }
}
</script>

<style scoped>

.bar-slider {
  height: 50px;
  overflow: hidden;
}

.bar-slides {
  will-change: transform;
  transition: transform 0.3s;
}

.bar-slide {
  height: 50px;
  text-align: right;
}


.btn-red, .btn-blue, .btn-white {
  height: 40px;
  margin-left: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  border: 0px;
}

.btn-red {
  background: #ff5964;
  border: 1px solid #FF5964;
}

.btn-white {
  color: #7D7D7D;
  background: #ffffff;
  border: 1px solid #7D7D7D;
}

.btn-white:hover {
  color: #707070;
}

.btn-blue:disabled {
  background: #ABD4FE;
}

.btn-red:disabled {
  background: #FFC6C9;
}

.reject-reason {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
}

.reject-reason-ok {
  margin-left: 12px;
}

</style>
