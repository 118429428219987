<template>
  <h2 class="mb-16">{{$t('ApplicantComments.comments_about_applicant')}} <img src="@/assets/images/card/edit.svg" @click="edit"></h2>
  <div class="flex mb-16">
    <div class="label">{{$t('ApplicantComments.summary')}}</div>
    <div class="value"><UiComment v-model="comments.summary"></UiComment></div>
  </div>
  <div class="flex mb-16 flex-vertical-center">
    <div class="label">{{$t('ApplicantComments.assesed_level')}}</div>
    <div class="value">
      <a-radio-group class="rg-assesed-level"
                     :value="student.confirmedLanguageLevel"
                     :defaultValue="student.confirmedLanguageLevel"
                     @change="setAssesedLevel"
      >
        <a-radio-button value="a1">
          {{$t('ApplicantComments.a1')}}
        </a-radio-button>
        <a-radio-button value="a2">
          {{$t('ApplicantComments.a2')}}
        </a-radio-button>
        <a-radio-button value="b1">
          {{$t('ApplicantComments.b1')}}
        </a-radio-button>
        <a-radio-button value="b2">
          {{$t('ApplicantComments.b2')}}
        </a-radio-button>
      </a-radio-group>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props,vue/no-side-effects-in-computed-properties */
import UiComment from "@/components/ui/UiComment";
export default {
  name: "ApplicantComments",
  components: {UiComment},
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  computed: {
    comments() {
      if (!this.student.detail.comments) {
        this.student.detail.comments = {};
      }
      if (!this.student.detail.comments.interviewAttended) {
        this.student.detail.comments.interviewAttended = {};
      }
      return this.student.detail.comments.interviewAttended;
    }
  },
  methods: {
    edit() {
      this.student.isChanged = true;
    },
    setAssesedLevel(e) {
      this.student.confirmedLanguageLevel = e.target.value
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  flex-basis: 123px;
  flex-grow: 0;
  flex-shrink: 0;

  font-size: 16px;
  line-height: 24px;
  color: #7D7D7D;
}

.value {
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  padding-right: 32px;
}

.rg-assesed-level {
  .ant-radio-button-wrapper {
    width: 60px;
    text-align: center;
  }
}

</style>
