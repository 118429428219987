<template>
<div class="prc_edit flex">
  <template v-if="!isEdit">
    {{ prc }} &nbsp;
    <UiPrcStatus :status="status" class="status"></UiPrcStatus>
    <img class="edit" src="@/assets/images/card/edit.svg" @click="edit">
  </template>
  <template v-else>
    <UiTextInput v-model:value="prcNew"></UiTextInput>
    <UiButtonOk class="btn_ok" @click="save"></UiButtonOk>
    <UiButtonClose class="btn_close" @click="close"></UiButtonClose>
  </template>
</div>
</template>

<script>
import UiPrcStatus from "../../ui/UiPrcStatus";
import UiTextInput from "../../ui/UiTextInput";
import UiButtonOk from "../../ui/UiButtonOk";
import UiButtonClose from "../../ui/UiButtonClose";
export default {
  name: "PrcEdit",
  components: {UiButtonClose, UiButtonOk, UiTextInput, UiPrcStatus},
  props: {
    detailId: {
      type: String,
      required: true,
    },
    prc: {
      type: String,
      default: '-',
    },
    status: {}
  },
  data() {
    return {
      isEdit: false,
      prcNew: '',
    }
  },
  methods: {
    edit() {
      this.prcNew = this.prc;
      this.isEdit = true;
    },
    save() {
      this.$emit('changed', this.prcNew);
      this.isEdit = false;
    },
    close() {
      this.isEdit = false;
      this.prcNew = '';
    }
  }
}
</script>

<style lang="scss">
.prc_edit {

  align-items: center;

  .btn_ok {
    margin-left: 4px;
  }

  .edit {
    cursor: pointer;
    margin-top: 3px;
    margin-left: 6px;
    // display: none;
    // opacity: 0;
    // transition: opacity 0.2s ease;
  }

  .status {
    margin-top: 2px;
  }

  .ant-input-lg {
    border: 1px solid #d9d9d9;
    padding: 3px 7px;
    width:160px;
  }
}
//.prc_edit:hover {
//  .edit {
//    // display: inline-block;
//    opacity: 1;
//  }
//}
</style>
