<template>
  <div class="flex flex-vertical-center mt-32 mb-16">
    <h2>{{$t('PaymentRegistration.payment_registration')}}</h2>
  </div>
  <div class="mb-16">
    {{$t('PaymentRegistration.check_all_the_details')}}
  </div>

  <div class="flex mb-16">
    <div class="label">{{$t('PaymentRegistration.date')}}</div>
    <div class="value">
      {{ $dayjs(payment.createdAt).format('MMM D, YYYY on hh:mm, ddd') }}
    </div>
  </div>

<!--  {{ payment }}-->


<!--  <div class="flex mb-16">-->
<!--    <div class="label">{{$t('PaymentRegistration.client')}}</div>-->
<!--    <div class="value">-->
<!--      <a-radio-group class="rg-mode"-->
<!--                     :value="payment.comment"-->
<!--                     :defaultValue="payment.comment"-->
<!--                     @change="setPaymentComment"-->
<!--      >-->
<!--        <a-radio-button value="student"-->
<!--                        :disabled="locked && payment.comment !== 'student'"-->
<!--        >-->
<!--          Student-->
<!--        </a-radio-button>-->
<!--        <a-radio-button value="employer"-->
<!--                        :disabled="locked && payment.comment !== 'employer'"-->
<!--        >-->
<!--          Employer-->
<!--        </a-radio-button>-->
<!--      </a-radio-group>-->
<!--    </div>-->
<!--  </div>-->

  <div class="flex mb-16">
    <div class="label">{{$t('PaymentRegistration.type')}}</div>
    <div class="value">
      <a-radio-group class="rg-mode"
                     :defaultValue="paymentType"
                     :value="paymentType"
      >
        <a-radio-button value="payment_by_card"
                        :disabled="paymentType !== 'payment_by_card'"
        >
          {{$t('PaymentRegistration.card')}}
        </a-radio-button>
        <a-radio-button value="payment_by_wire_transfer"
                        :disabled="paymentType !== 'payment_by_wire_transfer'"
        >
          {{$t('PaymentRegistration.wire')}}
        </a-radio-button>
      </a-radio-group>
    </div>
  </div>


<!--  <div class="flex mb-16">-->
<!--    <div class="label">{{$t('PaymentRegistration.level')}}</div>-->
<!--    <div class="value">-->
<!--      <a-radio-group class="rg-payment-level"-->
<!--                     :value="payment.level"-->
<!--                     :defaultValue="payment.level"-->
<!--                     @change="setPaymentLevel"-->
<!--      >-->
<!--        <a-radio-button value="a1"-->
<!--                        :disabled="locked && payment.level !== 'a1'"-->
<!--        >-->
<!--          A1-->
<!--        </a-radio-button>-->
<!--        <a-radio-button value="a2"-->
<!--                        :disabled="locked && payment.level !== 'a2'"-->
<!--        >-->
<!--          A2-->
<!--        </a-radio-button>-->
<!--        <a-radio-button value="b1"-->
<!--                        :disabled="locked && payment.level !== 'b1'"-->
<!--        >-->
<!--          B1-->
<!--        </a-radio-button>-->
<!--        <a-radio-button value="b2"-->
<!--                        :disabled="locked && payment.level !== 'b2'"-->
<!--        >-->
<!--          B2-->
<!--        </a-radio-button>-->
<!--      </a-radio-group>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="flex mb-16">-->
<!--    <div class="label">{{$t('PaymentRegistration.mode')}}</div>-->
<!--    <div class="value">-->
<!--      <a-radio-group class="rg-mode"-->
<!--                     :value="payment.mode"-->
<!--                     :defaultValue="payment.mode"-->
<!--                     @change="setPaymentMode"-->
<!--      >-->
<!--        <a-radio-button value="full_payment"-->
<!--                        :disabled="locked && payment.mode !== 'full_payment'"-->
<!--        >-->
<!--          Full payment-->
<!--        </a-radio-button>-->
<!--        <a-radio-button value="instalment"-->
<!--                        :disabled="locked && payment.mode !== 'instalment'"-->
<!--        >-->
<!--          Instalment-->
<!--        </a-radio-button>-->
<!--      </a-radio-group>-->
<!--    </div>-->
<!--  </div>-->


  <div class="flex mb-16">
    <div class="label">{{$t('PaymentRegistration.currency')}}</div>
    <div class="value">
      <a-radio-group class="rg-mode"
                     :value="payment.currency"
                     :defaultValue="payment.currency"
                     @change="setPaymentCurrency"
      >
        <a-radio-button value="euro"
                        :disabled="locked && payment.currency !== 'euro'"
        >
          <div class="euro_icon"></div>
          {{$t('PaymentRegistration.eur')}}
        </a-radio-button>
        <a-radio-button value="dollar"
                        :disabled="locked && payment.currency !== 'dollar'"
        >
          <div class="dollar_icon"></div>
          {{$t('PaymentRegistration.usd')}}
        </a-radio-button>
      </a-radio-group>
    </div>
  </div>

<!--  <div class="flex mb-16" style="align-items: center">-->
<!--    <div class="label">{{$t('PaymentRegistration.required_amount')}}</div>-->
<!--    <div class="value w276">-->
<!--      <UiTextInput v-model:value="price" :suffix="suffix" disabled></UiTextInput>-->
<!--    </div>-->
<!--  </div>-->

  <div class="flex mb-16" style="align-items: center">
    <div class="label">{{$t('PaymentRegistration.paid_amount')}}</div>
    <div class="value w276">
      <UiTextInput v-model:value="payment.paidAmount" :suffix="suffix"
                   :disabled="locked"></UiTextInput>
    </div>
  </div>

</template>

<script>
/* eslint-disable vue/no-mutating-props */

import UiTextInput from "@/components/ui/UiTextInput";

export default {
  name: "PaymentRegistration",
  components: {UiTextInput},
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payment: {}
    }
  },
  computed: {

    suffix() {
      if (this.payment.currency === 'euro') return '€';
      if (this.payment.currency === 'dollar') return '$';
      return ' ';
    },

    locked() {
      return this.payment.locked;
    },

    price() {
      // const prices = {
      //   euro: {
      //     a1: 430,
      //     a2: 430,
      //     b1: 645,
      //     b2: 645,
      //   },
      //   dollar: {
      //     a1: 520,
      //     a2: 520,
      //     b1: 780,
      //     b2: 780,
      //   }
      // };
      // try {
      //   let price = prices[this.payment.currency][this.payment.level];
      //   if (this.payment.mode === 'instalment') price = price / 2;
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.payment.requiredAmount = price;
      //   return price;
      // } catch (e) {
      //   console.log(this.payment.currency, this.payment.level);
      //   console.log(e);
      // }
      return 123;
    },
    statusRevStr() {
      return this.student.statusesRev.map((status) => status.status).join(', ');
    },
    paymentType() {
      let i1 = this.statusRevStr.indexOf('payment_by_card');
      if (i1 < 0) i1 = Infinity;
      let i2 = this.statusRevStr.indexOf('payment_by_wire_transfer');
      if (i2 < 0) i2 = Infinity;
      console.log(i1, i2);
      return (i1 < i2) ? 'card' : 'wire_transfer';
    }
  },
  methods: {

    setPaymentLevel(e) {
      this.payment.level = e.target.value
    },
    setPaymentMode(e) {
      this.payment.mode = e.target.value
    },
    setPaymentCurrency(e) {
      this.payment.currency = e.target.value
    },
    setPaymentComment(e) {
      this.payment.comment = e.target.value
    },

    bindButtons() {

      this.student.onSave = async () => {
        console.log('onSave');

        try {
          const {data} = await this.$store.dispatch('payments/find', {
            query: {
              studentId: this.student.id
            }
          });
          this.$log(data);

          if (data.length) {
            const payment = data[0];
            const patchResult = await this.$store.dispatch('payments/patch', [
              payment.id,
              this.payment
            ]);
            this.$log(patchResult);
            this.student.payment = this.payment;
          } else {
            const patchResult = await this.$store.dispatch('payments/create', {
              ...this.payment,
              studentId: this.student.id,
              paymentBy: 'wire_transfer'
            });
            this.$log(patchResult);
            this.student.payment = this.payment;
          }
        } catch (error) {
          this.$notify.parseFeathersErrors(error);
        }

      }

      // cancel changes
      this.student.onCancel = function () {
        this.student.isChanged = false;
        console.log('onCancel');
      }

      console.log("bind payment buttons");
    }
  },
  async mounted() {
    const {data} = await this.$store.dispatch('payments/find', {
      query: {
        studentId: this.student.id
      }
    });

    let newPay = {};
    if (data.length) {
      newPay = data[0];
      if (newPay.paidAmount) {
        const resets = {
          locked: true,
        };
        newPay = {...newPay, ...resets}
      } else {
        const resets = {
          requiredAmount: null,
          paidAmount: null,
          currency: null,
          level: null,
          mode: null,
          comment: null,
        };
        newPay = {...newPay, ...resets}
      }
    }

    this.payment = newPay;
    this.bindButtons();
  },

  watch: {
    payment: {
      handler(newValue
          , oldValue
      ) {
        console.log('handler watch 1', oldValue, this.student );
        //if (!oldValue?.status ) return;
        if (!this.student ) return;
        console.log('handler watch 2');
        this.student.currentPayment = newValue;
        this.student.isChanged = true;
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.label {
  flex-basis: 150px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 24px;
  color: #7D7D7D;
}

.value {
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}

.w276 {
  width: 276px;
}

.rg-mode {
  .ant-radio-button-wrapper {
    width: 138px;
    text-align: center;
  }
}

.rg-payment-level {
  .ant-radio-button-wrapper {
    width: 70px;
    text-align: center;
  }
}


.euro_icon {
  top: -2px;
  position: relative;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  background-color: #383838;
  mask-image: url("~@/assets/images/icons/euro.svg");
  mask-repeat: no-repeat;
}

.dollar_icon {
  top: -2px;
  position: relative;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  background-color: #383838;
  mask-image: url("~@/assets/images/icons/dollar.svg");
  mask-repeat: no-repeat;
}



.ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper:hover {
  .euro_icon {
    background-color: #258FFB;
  }

  .dollar_icon {
    background-color: #258FFB;
  }
}

.ant-radio-button-wrapper-disabled,
.ant-radio-button-wrapper-disabled:hover {
  .euro_icon {
    background-color: #BFBFBF;
  }

  .dollar_icon {
    background-color: #BFBFBF;
  }
}

.ant-input {
  border-right: 4px;
}
</style>
