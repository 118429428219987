<template>
  <table class="ui-table mb-16">
    <tr>
      <th>
        <div class="star-pad">
          <UiStar></UiStar>
        </div>
      </th>

      <th>
        <div>
          <SortIcon name="firstName" :ds="ds">{{
            $t('Review.applicant')
          }}</SortIcon>
        </div>
      </th>
      <th>
        <div>
          <SortIcon name="detail.departments" :ds="ds">{{
            $t('Review.hospital_departments')
          }}</SortIcon>
        </div>
      </th>
      <th title="Total experience">
        <div>
          <SortIcon name="detail.countExperience" :ds="ds">{{
            $t('Review.te')
          }}</SortIcon>
        </div>
      </th>
      <th title="Working abroad">
        <div>
          <SortIcon name="detail.countAbroadExperience" :ds="ds">{{
            $t('Review.wa')
          }}</SortIcon>
        </div>
      </th>
      <th title="Student motivation">
        <div>{{ $t('Review.conf') }}</div>
      </th>
      <th>
        <div>
          <SortIcon name="createdAt" :ds="ds">{{
            $t('Review.applying_date')
          }}</SortIcon>
        </div>
      </th>
      <th>
        <div>{{ $t('Review.cv') }}</div>
      </th>
    </tr>

    <tr
      v-for="item in items"
      :key="item.id"
      @click="$emit('select', item)"
      class="hoverable"
      :class="{ hover: item.id === currentItemId }"
    >
      <td>
        <div class="table-cell star-pad">
          <UiStar :user="item"></UiStar>
        </div>
      </td>

      <td>
        <div class="table-cell nowrap">
          <UiAvatar
            class="user-avatar"
            :src="getAvatarUrl(item?.user?.photo?.key)"
          ></UiAvatar>
          <span>
            {{ item.user.firstName }}&nbsp;{{ item.user.middleName }}&nbsp;{{
              item.user.lastName
            }}
            <div class="mini-date">
              {{ $dayjs(item.createdAt).format('DD/MM/YYYY') }}
            </div>
          </span>
        </div>
      </td>
      <td>
        <UiDepartaments :departments="item.detail.departments" />
      </td>
      <td>{{ item.detail.countExperience }}</td>
      <td>{{ item.detail.countAbroadExperience }}</td>
      <td>-</td>
      <td class="nowrap">{{ $dayjs(item.createdAt).format('DD/MM/YYYY') }}</td>
      <td>
        <div class="cv" v-if="item?.cv?.key" @click.stop="downloadCV(item)"></div>
        <div v-else>n/a</div>
      </td>
    </tr>
  </table>
</template>

<script>
import config from '@/config'
import UiAvatar from '@/components/ui/UiAvatar'
import UiDepartaments from '@/components/ui/UiDepartaments'
import UiStar from '@/components/ui/UiStar'
import SortIcon from '@/components/SortIcon'

export default {
  name: 'Review',
  components: { SortIcon, UiStar, UiDepartaments, UiAvatar },
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentItemId: {},
    ds: {
      type: Object,
      required: true,
    },
  },
  emits: ['select'],
  methods: {
    getAvatarUrl(key) {
      if (key)
        return `${config.backendUrl}/${key}?token=${localStorage['feathers-jwt']}`
      else return require('@/assets/images/no-avatar.jpg')
    },
    downloadCV(student) {
      const url = `${config.backendUrl}/${student.cv.key}?token=${localStorage['feathers-jwt']}`
      this.$log(url)
      window.open(url)
    },
  },
}
</script>

<style scoped></style>
