<template>
  <div>
    <h2 class="mb-24">{{$t('ProfessionalDetails.professional_details')}}</h2>
    <div class="flex mb-48">
      <div class="col1">
        <div class="flex">
          <div class="name">{{$t('ProfessionalDetails.profession')}}</div>
          <div class="value">{{ student.detail.currentProfession ?? '-' }}</div>
        </div>
        <template v-if="student.detail.hasAllowedProfession">
          <div class="flex">
            <div class="name">{{$t('ProfessionalDetails.prc_license')}}</div>
            <div class="value flex-vertical-center prc">
              <PrcEdit
                  :detailId="student.detail.id"
                  :prc="student.detail.prc"
                  :status="student.detail.isPrcValid"
                  @changed="updatePrc($event)"
              ></PrcEdit>
            </div>
          </div>
          <div class="flex">
            <div class="name">{{$t('ProfessionalDetails.contract')}}</div>
            <div class="value">
              {{ student.detail.сontractExpire ? 'exp. ' + $dayjs(student.detail.сontractExpire).format('DD/MM/YYYY') : '-' }}
            </div>
          </div>
          <div class="flex mb-16">
            <div class="name">{{$t('ProfessionalDetails.departments')}}</div>
            <div class="value departments">
              <span v-for="department in student.detail.departments" :key="department.id">{{ department.name }}</span>
            </div>
          </div>
        </template>
        <div class="flex">
          <div class="name">{{$t('ProfessionalDetails.cv')}}</div>
          <div class="value cv text-cut" @click="downloadCV">{{ student.cv.name }}</div>
        </div>
      </div>
      <template v-if="student.detail.hasAllowedProfession">
        <div class="col2">
          <div class="name">{{$t('ProfessionalDetails.total_experience')}}</div>
          <div class="value">{{ student.detail.countExperience ?? '-' }} years</div>
        </div>
        <div class="col3">
          <div class="name">{{$t('ProfessionalDetails.working_abroad')}}</div>
          <div class="value">{{ student.detail.countAbroadExperience ?? '-' }} years</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import UiPrcStatus from "@/components/ui/UiPrcStatus";
import PrcEdit from "./PrcEdit";

export default {
  name: "ProfessionalDetails",
  components: {PrcEdit, UiPrcStatus},
  props: {
    student: {
      type: Object,
      required: true,
    }
  },
  computed: {},
  methods: {
    async updatePrc(value) {
      // !TODO add loading.gif
      try {
        await this.$store.dispatch('students-details/patch', [
            this.student.detail.id, { prc: value }
        ]);
        this.$notify.success("PRC changed!");
        // eslint-disable-next-line vue/no-mutating-props
        this.student.detail.prc = value;
      } catch (error) {
        console.log(error);
        this.$notify.parseFeathersErrors(error);
      }
    },
    downloadCV() {
      const url = `${config.backendUrl}/${this.student.cv.key}?token=${localStorage['feathers-jwt']}`
      this.$log(url);
      window.open(url);
    },
  }
}
</script>

<style scoped lang="scss">
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: #272848;
}

.col1 {

  flex-grow: 1;

  .flex {
    font-size: 16px;
    min-height: 48px;
    display: flex;
    align-items: flex-start;

    .name {
      flex: 0 0 120px;
      color: #7D7D7D;
    }

    .value {
      color: #383838;
    }

    .cv {
      background-image: url("~@/assets/images/card/cv.svg");
      background-position: 0px center;
      background-repeat: no-repeat;
      padding-left: 30px;
      padding-right: 12px;
      color: #258FFB;
      cursor: pointer;
    }

    .departments span {
      display: inline-block;
      padding: 6px 12px;
      background: #E1E5F9;
      border-radius: 4px;
      margin-right: 6px;
      margin-bottom: 6px;
      white-space: nowrap;
      font-size: 90%;
    }
  }
}

.col2, .col3 {
  .name {
    font-size: 16px;
    line-height: 24px;
    color: #7D7D7D;
    margin-bottom: 10px;
  }

  .value {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #383838;
  }
}

.col2 {
  flex: 0 0 150px;
}

.col3 {
  flex: 0 0 120px;
}
</style>
