<template>
  <div class="mb-32">
    <h2 class="mb-16">{{$t('DeclineReason.reason_for_decline')}}</h2>
    <div class="flex mb-8">
      <div class="name">{{$t('DeclineReason.date')}}</div>
      <div class="value">{{ $dayjs(lastStatus.createdAt).format('DD/MM/YYYY') }}</div>
    </div>
    <div class="flex mb-8">
      <div class="name">{{$t('DeclineReason.comment')}}</div>
      <div class="value">{{lastStatus.comment}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeclineReason",
  props: {
    lastStatus: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped>
.name {
  flex: 0 0 120px;
  color: #7D7D7D;
  font-size: 16px;
}

.value {
  color: #383838;
  font-size: 16px;
}
</style>