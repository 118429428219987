<template>
    <div class="ui-avatar" :style="{backgroundImage: 'url('+src+')'}"></div>
</template>

<script>
export default {
    name: "UiAvatar",
    props: {
        src:{
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>
.ui-avatar {
    display: inline-block;
    background-size: cover;
    border-radius: 50%;
}
</style>
