<template>
  <div class="demo wrapper">
    <ButtonBar class="grow mb-16" :student="student" @pushStatus="pushStatus"></ButtonBar>

    <TableCardHeader class="mb-48" :student="student"></TableCardHeader>

    <MiniTabs class="mb-24 bb-1g" :tabs="filteredCardTabs" @openTab="openTab"></MiniTabs>

    <transition :name="transitionName" mode="out-in">

      <div v-if="currentTab.id == 1" key="1">
        <DeclineReason v-if="student.isRejected" :lastStatus="student.lastStatus"></DeclineReason>
        <template v-if="true">
          <ProfessionalDetails :student="student" class="mb-40"></ProfessionalDetails>
          <!-- TODO: remove? -->
          <StudentMotivation v-if="student?.detail?.motivation" :motivation="student?.detail?.motivation"></StudentMotivation>
        </template>
        <!-- <StillAdding v-else></StillAdding> -->
      </div>

      <div v-else-if="currentTab.id == 2" key="2">
        <div class="flex">
          <div class="eva-col1">
            <InterviewDetails :student="student" :pushStatus="pushStatus"></InterviewDetails>
            <ApplicantComments v-if="student.attendance === 'attended'" :student="student"></ApplicantComments>
            <MissedReason v-if="student.attendance === 'missed'" :student="student"></MissedReason>
          </div>
          <div class="eva-col2">
            <History :student="student"></History>
          </div>
        </div>
      </div>

      <div v-else-if="currentTab.id == 3" key="3">
        <div class="flex">
          <div class="eva-col1">
            <PaymentNotice :student="student" @reloadTab="$emit('reloadTab')"></PaymentNotice>
            <!-- v-if="student.lastStatusName === 'payment_confirming'"  -->
            <PaymentRegistration :student="student"></PaymentRegistration>
            <PaymentCardDetails :student="student"></PaymentCardDetails>
          </div>
          <div class="eva-col2">
            <History :student="student"></History>
          </div>
        </div>
      </div>

      <div v-else-if="currentTab.id == 4" key="4">
        <div class="flex">
          <div class="eva-col1">
            <PreSeedProgress :student="student"></PreSeedProgress>
          </div>
          <div class="eva-col2">
            <History :student="student"></History>
          </div>
        </div>
      </div>

      <div v-else-if="currentTab.id == 5" key="5">
        <PaymentHistory :student="student" />
      </div>

    </transition>

    <div class="card-arrow actively" @click="$emit('hideCard')">&#8594;</div>

  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import TableCardHeader from "@/components/students/card/TableCardHeader";
import ProfessionalDetails from "@/components/students/card/ProfessionalDetails";
import StudentMotivation from "@/components/students/card/StudentMotivation";
import MiniTabs from "@/components/students/card/MiniTabs";
import History from "@/components/students/card/History";
import ButtonBar from "@/components/students/card/ButtonBar";
import "@/assets/styles/transitions.scss"
import InterviewDetails from "@/components/students/card/InterviewDetails";
import ApplicantComments from "@/components/students/card/ApplicantComments";
import PaymentNotice from "@/components/students/card/PaymentNotice";
import PaymentRegistration from "@/components/students/card/PaymentRegistration";
import PaymentCardDetails from "@/components/students/card/PaymentCardDetails";
import MissedReason from "@/components/students/card/MissedReason";
import DeclineReason from "@/components/students/card/DeclineReason";
import PreSeedProgress from "@/components/students/card/PreSeedProgress";
import PaymentHistory from "@/components/students/card/PaymentHistory";
import StillAdding from "@/components/students/card/StillAdding";

export default {
  name: "TableCard",
  components: {
    StillAdding,
    PaymentHistory,
    PreSeedProgress,
    DeclineReason,
    MissedReason,
    PaymentCardDetails,
    PaymentRegistration,
    PaymentNotice,
    ApplicantComments,
    InterviewDetails,
    ButtonBar,
    History,
    MiniTabs,
    StudentMotivation,
    ProfessionalDetails,
    TableCardHeader
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
    tabNames: {
      type: Array
    }
  },
  emits: ['hideCard', 'nextCard', 'reloadTab'],
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: 'applicant_info',
          title: "Applicant info"
        },
        {
          id: 2,
          name: 'evaluation',
          title: "Evaluation"
        },
        {
          id: 3,
          name: 'payment_info',
          title: "Payment info"
        },
        {
          id: 4,
          name: 'progress',
          title: "Progress"
        },
        {
          id: 5,
          name: 'payment_history',
          title: "Payment history"
        },
      ],
      currentTab: '',
      transitionName: '',
    }
  },
  computed: {
    filteredCardTabs() {
      return this.tabs.filter((tab) => this.tabNames.includes(tab.name))
    }
  },
  methods: {
    openTab(tab) {
      this.transitionName = (tab.id > this.currentTab.id) ? 'slide-left' : 'slide-right';
      this.currentTab = tab;
      console.log(this.currentTab.id);
    },

    // TODO: move to vuex
    async pushStatus({status, comment, withoutNextCard}) {
      try {
        const studentId = this.student.id;
        const undoText = `${this.student.user.firstName} ${this.student.user.middleName} ${this.student.user.lastName} moving to ${status}`;
        this.$store.state.undo(undoText, this.student);

        let response = await this.$store.dispatch('student-statuses/create', {
          status: status,
          comment: comment,
          studentId: studentId
        });
        this.$log(response);
        if (!withoutNextCard) this.$emit('nextCard');
        else {
          // nead refresh
          this.$emit('reloadTab');
        }
      } catch (error) {
        this.$notify.parseFeathersErrors(error);
      }


      // if choosing_payment
      // if (status === 'choosing_payment') {
      //   const paymentData = {
      //     status: 'await',
      //     dueDate: new Date(),
      //     dateOfPayment: new Date(),
      //     requiredAmount: 430,
      //     paidAmount: 0,
      //     currency: 'euro',
      //     level: 'a1',
      //     mode: 'full_payment',
      //     comment: 'test',
      //   };
      //   try {
      //     let response = await this.$store.dispatch('payments/create', {
      //       ...paymentData,
      //       studentId: this.student.id
      //     });
      //     this.$log(response);
      //   } catch (error) {
      //     this.$notify.parseFeathersErrors(error);
      //   }
      // }


    },
  }
}
</script>

<style scoped>

.bb-1g {
  border-bottom: 1px solid #e7e8ec;
}

.demo {
  width: 801px;
  background: url("~@/assets/images/demo2.png");
}

.wrapper {
  background: #ffffff;
  padding: 30px 32px;
  position: relative;
}

.card-arrow {
  position: absolute;
  left: 0px;
  top: 32px;
  width: 36px;
  height: 36px;
  background: #F5F8FF;
  border-radius: 0px 4px 4px 0px;
  font-size: 20px;
  padding-left: 6px;
  cursor: pointer;
  transition: background .2s ease;
}

.card-arrow:hover {
  background: #E1E5F9;
}


.mb-48 {
  margin-bottom: 48px;
}


.eva-col1 {
  flex-grow: 1;
}

.eva-col2 {
  margin-left: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 270px;
}
</style>
