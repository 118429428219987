<template>
  <div>
<!--    <h2 class="mb-24">{{$t('PaymentNotice.payment_map')}}</h2>-->

    <div class="payment-list">
      <div class="line"></div>
      <div class="payment-item" v-for="payment in payments" :key="payment.id" :class="payment.classes">
        <div class="title">{{payment.title}}</div>
        <div class="circle">{{payment.level}}</div>
        <div class="price">{{payment.price}}</div>
        <div class="date">{{payment.createdAt}}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "PaymentNotice",
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  emits: ['reloadTab'],
  computed: {
    payments() {
      const payments = [
        {
          id: 1,
          title: '',
          level: 'A1',
          price: '',
          createdAt: '-',
          status: 'payment_empty',
        },
        {
          id: 2,
          title: '',
          level: 'A2',
          price: '',
          createdAt: '-',
          status: 'payment_empty',
        },
        {
          id: 3,
          title: '',
          level: 'B1',
          price: '',
          createdAt: '-',
          status: 'payment_empty',
        },
        {
          id: 4,
          title: '',
          level: 'B2',
          price: '',
          createdAt: '-',
          status: 'payment_empty',
        },
      ];

      const level = this.student.currentPayment.level || 'a1';
      const currency = this.student.currentPayment.currency || 'euro';
      const requiredAmount = this.student.currentPayment.requiredAmount || 430;

      console.log('PW:',level, );

      return payments.map((payment) => {

        // enreach
        if (currency
            && payment.level.toLowerCase() === level
        ) {
          payment.title = 'next payment';
          payment.status = 'payment_wait';
          let suffix = (currency === 'euro') ? ' €' : ' $'
          payment.price = requiredAmount + suffix;

          console.log('PW:ok');


          const p = this.student.currentPayment;
          payment.createdAt = this.$dayjs(p.createdAt).format('DD/MM/YYYY');
          if (p.paidAmount > 0) payment.status = 'payment_done';

        }

        const classes = {};
        classes[payment.status] = true;
        payment.classes = classes;
        return payment;
      });
    },
    payment() {
      return this.student?.payment ? this.student.payment : {
        requiredAmount: 0,
        paidAmount: 0,
      };
    },
    status() {
      if (this.payment.requiredAmount <= this.payment.paidAmount) return 'paid';
      else return 'await';
    }
  },
  methods: {
    //
  },
  async mounted() {
    try {
      const {data} = await this.$store.dispatch('payments/find', {
        query: {
          studentId: this.student.id
        }
      });
      this.$log(data);

      if (data.length) {
        // eslint-disable-next-line vue/no-mutating-props
        this.student.payments = data;
      }
    } catch (error) {
      this.$notify.parseFeathersErrors(error);
    }
  }
}
</script>

<style scoped lang="scss">


@keyframes payment-bg {
  0% {
    background-color: #258FFB;
  }
  50% {
    background-color: #1a70bf;
  }
  100% {
    background-color: #258FFB;
  }
}

.payment-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  margin-right: 50px;

  .line {
    position: absolute;
    top: 56px;
    left: 30px;
    width: calc(100% - 60px);
    height: 2px;
    background: #D9D9D9;
  }

  .payment-item {
    width: 80px;
    text-align: center;
    z-index: 1;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.045em;
      text-transform: uppercase;
      color: #BFBFBF;
      height: 34px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .circle {
      width: 32px;
      height: 32px;
      background-color: #BFBFBF;
      border: 3px solid white;
      box-sizing: content-box;
      color: #ffffff;
      border-radius: 50%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      line-height: 22px;

      margin-top: 4px;
      margin-bottom: 4px;
    }

    .price {
      font-size: 16px;
      line-height: 24px;
      color: #383838;
      height: 24px;
    }

    .date {
      font-size: 14px;
      line-height: 22px;
      color: #BFBFBF;
    }
  }

  .payment_wait {
    .circle {
      background-color: #258FFB;
      color: #ffffff;
      //animation: payment-bg 1s infinite;
    }
  }
  .payment_done {
    .circle {
      background-color: #258FFB;
      color: #ffffff;
    }
  }
}

//
//
//.flex-vertical-center {
//  align-items: center;
//}
//
//.label {
//  flex-basis: 123px;
//  flex-grow: 0;
//  flex-shrink: 0;
//  font-size: 16px;
//  line-height: 24px;
//  color: #7D7D7D;
//}
//
//.value {
//  font-size: 16px;
//  line-height: 24px;
//  color: #383838;
//}
</style>
