<template>
  <button class="ui-button-ok"></button>
</template>

<script>
export default {
name: "UiButtonOk"
}
</script>

<style scoped>
.ui-button-ok {
  border: 0px;
  outline: 0px;
  width: 32px;
  height: 32px;
  background-color: #258FFB;
  background-image: url("~@/assets/images/icons/check.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
}
</style>
