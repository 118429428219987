<template>
  <div class="flex" v-if="student">
    <div class="col-ava">
      <div class="mb-8">
        <UiAvatar
          v-if="student?.user?.photo?.key"
          :src="avatarUrl"
          class="avatar"
        ></UiAvatar>
        <UiAvatar
          v-else
          class="avatar"
          :src="require('@/assets/images/no-avatar.jpg')"
        ></UiAvatar>
      </div>
      <div class="text-14">{{ $t('TableCardHeader.german_level') }}</div>
      <div class="title-18 uppercase">
        {{
          student?.confirmedLanguageLevel ||
            student?.initialLanguageLevel ||
            '-'
        }}
      </div>
    </div>
    <div class="col-info">
      <div class="title-24 mb-24">
        <template v-if="firstName"
          >{{ firstName }} {{ middleName }}
          {{ lastName }}</template
        >
        <template v-else>{{ student?.email }}</template>
        <UiStar
          v-if="student?.detail?.isFavorite"
          class="star"
          :user="student"
        />
      </div>
      <div class="flex">
        <div class="col-fields">
          <div class="flex">
            <div class="name">{{ $t('TableCardHeader.date_of_birth') }}</div>
            <div class="value">{{ dateOfBirth }}</div>
          </div>
          <div class="flex">
            <div class="name">{{ $t('TableCardHeader.gender') }}</div>
            <div class="value">{{ gender }}</div>
          </div>
          <div class="flex">
            <div class="name">Citizenship:</div>
            <div class="value">{{ student?.citizenship?.name ?? '-' }}</div>
          </div>
        </div>
        <div class="col-social">
          <div class="flex">
            <div class="value mail">{{ student?.email }}
              <span v-if="isVerified" class="is-verified"> (verified)</span>
            </div>
          </div>
          <div class="flex">
            <div class="value phone">{{ student?.phone }}</div>
          </div>
          <div class="flex" v-if="student?.country?.name">
            <div class="value place">
              {{ student?.city }}, {{ student?.country?.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiStar from '@/components/ui/UiStar'
import UiAvatar from '@/components/ui/UiAvatar'
import config from '@/config'
export default {
  name: 'TableCardHeader',
  components: { UiAvatar, UiStar },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  computed: {
    firstName() {
      return this.student?.user?.firstName ||
        this.student?.root?.firstName;
    },
    middleName() {
      return this.student?.user?.middleName ||
        this.student?.root?.middleName;
    },
    lastName() {
      return this.student?.user?.lastName ||
        this.student?.root?.lastName;
    },
    isVerified() {
      return this.student?.root?.isVerified || false;
    },
    dateOfBirth() {
      if (!this.student.dateOfBirth) return '-'
      const dayjs = this.$dayjs(this.student.dateOfBirth)
      const age = -dayjs.diff(new Date(), 'year')
      return dayjs.format('MMM DD, YYYY') + ' (age ' + age + ')'
    },
    gender() {
      // TODO: remove root
      const user = this.student?.root ?? this.student?.user;
      if (!user?.gender) return '-'
      return user.gender == 'f' ? 'Female' : 'Male'
    },
    // civilStatus() {
    //     return this.student.isMarried ? 'Married' : 'Single'
    // },
    avatarUrl() {
      return `${config.backendUrl}/${this.student?.user?.photo?.key}?token=${localStorage['feathers-jwt']}`
    },
  },
}
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.col-ava {
  flex: 0 0 121px;
  padding-top: 4px;
}
.is-verified {
  color: green;
}
.avatar {
  width: 91px;
  height: 91px;
}
.star {
  position: relative;
  top: 4px;
  left: 10px;
}
.col-fields {
  width: 354px;
  flex-shrink: 0;
  & > .flex {
    margin-bottom: 12px;
  }
  .name {
    width: 120px;
    font-size: 16px;
    line-height: 24px;
    color: #7d7d7d;
  }
  .value {
    font-size: 16px;
    line-height: 24px;
    color: #383838;
  }
}
.col-social {
  & > .flex {
    margin-bottom: 12px;
  }
  .value {
    font-size: 16px;
    line-height: 24px;
    color: #383838;
  }

  .mail,
  .phone,
  .place {
    background-position: 0px center;
    background-repeat: no-repeat;
    padding-left: 30px;
  }
  .mail {
    background-position: 1px center;
    background-image: url('~@/assets/images/card/mail.svg');
  }
  .phone {
    background-position: 2px center;
    background-image: url('~@/assets/images/card/whatsapp.svg');
  }
  .place {
    background-position: 0px center;
    background-image: url('~@/assets/images/card/environment.svg');
  }
}
</style>
