<template>
<div class="flex flex-center flex-vertical-center" style="height: 300px">
  <div class="flex-column flex-vertical-center">
    <div class="img mb-32"></div>
    <div class="title-18 mb-8">{{$t('StillAdding.applicant_is_still_adding')}}</div>
    <div class="text-16">{{$t('StillAdding.give_him_a_little_push')}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: "StillAdding"
}
</script>

<style scoped>
.img {
  width: 50px;
  height: 50px;
  background-image: url("~@/assets/images/still_adding.png");
  background-repeat: no-repeat;
  background-size: contain;
}
</style>