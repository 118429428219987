<template>
  <button class="ui-button-close"></button>
</template>

<script>
export default {
  name: "UiButtonClose"
}
</script>

<style scoped>
.ui-button-close {
  border: 0px;
  outline: 0px;
  width: 32px;
  height: 32px;
  background-color: #FFFFFF;
  background-image: url("~@/assets/images/icons/close.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: pointer;
}
</style>
