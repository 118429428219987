<template>
  <a-button class="button" :class="{ wide, red }">
    <slot v-if="!awaiting" />
    <LoadingOutlined v-else />
  </a-button>
</template>

<script>
import { LoadingOutlined } from "@ant-design/icons-vue"
export default {
  name: "UiButton",
  components: {
    LoadingOutlined,
  },
  props: {
    awaiting: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    red: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped lang="scss">
.button {
  height: 48px;
  font-size: 18px;
  color: #eeeeee;
  background: #258ffb;
  border-radius: 4px;
}
.button:hover {
  color: #ffffff;
}
.wide {
  width: 100%;
}

.red {
  background: #ff5964;
}
</style>
