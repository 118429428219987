<template>
  <h2 class="mb-16">{{$t('PreSeedProgress.communication')}}</h2>
  <div class="flex mb-16">
    <div class="label">{{$t('PreSeedProgress.applying_date')}}</div>
    <div class="value">
      {{ $dayjs(student.createdAt).format('DD/MM/YYYY') }}
    </div>
  </div>
  <div class="flex mb-16">
    <div class="label">{{$t('PreSeedProgress.last_update')}}</div>
    <div class="value">
      {{ $dayjs(student.updatedAt).format('DD/MM/YYYY') }}
    </div>
  </div>
  <div class="flex mb-16">
    <div class="label">{{$t('PreSeedProgress.current_stage')}}</div>
    <div class="value">
      -
    </div>
  </div>
  <div class="flex mb-16">
    <div class="label">{{$t('PreSeedProgress.status')}}</div>
    <div class="value">

      <a-select
          class="reject-reason-select"
          placeholder="please select status"
          :value="student?.detail?.comments?.preSeeds"
          @change="onChange"
      >
        <a-select-option v-for="(comment,index) in comments" :key="index" :value="comment">
          {{ comment }}
        </a-select-option>
      </a-select>

    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

export default {
  name: "PreSeedProgress",
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comments: [
        'Contacted, Planning to finish...',
        'Contacted, Has technical problems',
        'Can’t be contacted, doesn’t reply to emails',
        'Remind in a few days',
        'Remind in 2 weeks',
        'Lost Interest in Scholarship',
      ],
    }
  },
  mounted() {
    this.bindButtons();
  },
  methods: {
    onChange(event) {
      console.log(event);
      if (this.student?.detail) {
        this.student.detail.comments = this.student.detail.comments || {};
        this.student.detail.comments.preSeeds = event
      }
      this.student.isChanged = true;
    },
    bindButtons() {
      console.log('on save binding done!')

      this.student.onSave = async () => {
        console.log('onSave');
        this.student.isChanged = false;

        try {
          await this.$store.dispatch('students-details/patch', [
            this.student.detail.id, {
              comments: this.student.detail.comments || {},
            }
          ]);
          console.log("comment saved!");
        } catch (error) {
          console.log(error);
          this.$notify.parseFeathersErrors(error);
        }

      }

      // cancel changes
      this.student.onCancel = () => {
        console.log('onCancel');
        this.student.isChanged = false;
      }
    }
  },
}
</script>

<style scoped lang="scss">
.label {
  flex-basis: 123px;
  flex-grow: 0;
  flex-shrink: 0;

  font-size: 16px;
  line-height: 24px;
  color: #7D7D7D;
}

.value {
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  padding-right: 32px;
}

</style>