<template>
  <ul class="ui_tabs" :class="{ ui_tabs_sliding: sliding }" ref="wrap">
    <template v-for="tab in tabs" :key="tab">
      <li class="ui_tab_wrapper nowrap" v-if="tab.name !== '|'">
        <a
          href="/"
          class="ui_tab ui_tab_hover"
          :class="{ ui_tab_sel: tab == currentTab }"
          @click.prevent="moveSlider(tab)"
        >
          {{ tab.title }}
          <template v-if="typeof tab.count === 'number'"
            >({{ tab.count }})</template
          >
        </a>
      </li>
      <li v-else>
        <div class="ui_tab">
          |
        </div>
      </li>
    </template>

    <div
      class="ui_tabs_slider _ui_tabs_slider"
      :style="sliderStyles"
      ref="tend"
    ></div>
  </ul>
</template>

<script>
// optimize this!!!
function ge(e) {
  return e
}

function getXY(obj) {
  obj = ge(obj)
  if (!obj) return [0, 0]

  var left = 0,
    top = 0
  if (obj.offsetParent) {
    do {
      left += obj.offsetLeft
      top += obj.offsetTop
      // eslint-disable-next-line no-cond-assign
    } while ((obj = obj.offsetParent))
  }
  return [left, top]
}

function getW(obj) {
  obj = ge(obj)
  return (obj && obj.offsetWidth) || 0
}

export default {
  name: 'MiniTabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    // initTab: {
    //   type: Function,
    //   default: function (tabs, moveSlider) {
    //     if (tabs && tabs.length) {
    //       moveSlider(tabs[0], true);
    //     }
    //   }
    // }
    tabName: {
      type: String,
      default: '',
    },
  },
  emits: ['openTab'],
  data() {
    return {
      currentTab: {},
      sliderX: 0,
      sliderW: 0,
      sliding: false,
    }
  },
  computed: {
    sliderStyles() {
      return {
        width: this.sliderW + 'px',
        transform: 'translateX(' + this.sliderX + 'px)',
      }
    },
  },

  methods: {
    moveSlider(t, force) {
      console.log(t)

      this.$emit('openTab', t)
      this.currentTab = t

      let tabs = this.$refs.wrap.getElementsByClassName('ui_tab')
      let i = this.tabs.indexOf(this.currentTab)

      console.log(i)

      if (!force) {
        this.sliding = true
        setTimeout(() => {
          this.sliding = false
        }, 250)
      }
      this.sliderX = getXY(tabs[i])[0] - getXY(this.$el)[0]
      this.sliderW = getW(tabs[i])
      // console.log(this.sliderX, this.sliderW);
    },
  },

  mounted() {
    if (this.tabs && this.tabs.length) {
      let foundTab = this.tabs[0]
      //this.preOpen = {...this.$route.query};
      if (this.tabName) {
        const found = this.tabs.find(t => t.name === this.tabName)
        if (found) foundTab = found
        // if (this.preOpen.pageNum) {
        //   foundTab.page = this.preOpen.pageNum;
        // }
        // if (this.preOpen.pageSize) {
        //   this.currentPageSize = this.preOpen.pageSize;
        // }
      }
      console.log('from 2 - ', foundTab)
      this.moveSlider(foundTab, true)
    }

    //if (this.initTab) this.initTab(this.tabs, this.moveSlider);
  },
  // watch: {
  //   tabs() {
  //     if (this.tabs.indexOf(this.currentTab) == -1) {
  //       if (this.tabs && this.tabs.length) {
  //         this.currentTab = this.tabs[0];
  //       }
  //     }
  //   }
  // }
}
</script>

<style>
/** {*/
/*  outline: 1px dashed green;*/
/*}*/

.ui_tabs {
  position: relative;
  /*border-bottom: 1px solid #e7e8ec;*/
  list-style: none;
  user-select: none;
  display: flex;
}

.ui_tab {
  display: block;
  padding: 8px 0px;
  margin-right: 32px;
  margin-bottom: -1px;

  /* Medium 14 caps */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: #bfbfbf;

  transition: color 0.2s ease;
}

.ui_tab_hover:hover {
  border-bottom: 2px solid #cad2db;
  cursor: pointer;
}

.ui_tab_sel,
.ui_tab_sel:hover {
  border-bottom: 2px solid #258ffb;
  color: #258ffb;
}

.ui_tabs_slider {
  position: absolute;
  transition: all 1s cubic-bezier(0, 0, 1, 1);
}

.ui_tabs_slider {
  position: absolute;
}

.ui_tabs.ui_tabs_sliding .ui_tabs_slider {
  z-index: 5;
  bottom: -1px;
  left: 0;
  width: 100px;
  height: 2px;
  background-color: #258ffb;
  transition: width 0.2s ease, transform 0.2s ease;
}

.ui_tabs.ui_tabs_sliding .ui_tab_sel {
  border-bottom: 2px solid #cad2db;
}

.ui_tabs.ui_tabs_sliding .ui_tab_group_sel:before {
  background: #cad2db;
}

.ui_tab_splitter {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
