<template>
  <a-select
      class="reject-reason-select"
      placeholder="please select rejection reason"
      v-bind="$attrs"
  >
    <a-select-option v-for="reason in filteredReasons" :key="reason.id" :value="reason.reason">
      {{ reason.reason }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "UiRejectReason",
  inheritAttrs: false,
  props: {
    status: {}
  },
  data() {
    return {
      reasons: []
    }
  },
  computed: {
    filteredReasons() {
      return this.reasons.filter((reason) => {
        if (reason.onStatus.indexOf('review') != -1 && this.status.indexOf('review') != -1) return true;
        if (reason.onStatus.indexOf('interview') != -1 && this.status.indexOf('interview') != -1) return true;
        if (reason.onStatus.indexOf('payment') != -1 && this.status.indexOf('payment') != -1) return true;
        return false;
      })
    }
  },
  async mounted() {
    const {data} = await this.$store.dispatch('reject-reasons/find', {
      query: {
        // TODO: change to finding by status
        $limit: 30
      }
    });
    this.$log(data);
    this.reasons = data;
  }
}
</script>

<style lang="scss">
.reject-reason-select {
  width: 250px;
  text-align: left;
  .ant-select-selector {
    border: 1px solid #D9D9D9 !important;
    border-radius: 4px !important;
  }
}
</style>
