<template>
  <div class="ui-comment">
    <textarea :rows="rowCount" class="ui-comment-text" :value="modelValue" @input="onChanged"></textarea>
  </div>
</template>

<script>
export default {
  name: "UiComment",
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: '',
    }
  },
  computed: {
    rowCount() {
      return Math.min(this.modelValue.split("\n").length, 5);
    }
  },
  methods: {
    onChanged(e) {
      this.$emit('update:modelValue', e.target.value)
    }
  }
}
</script>

<style scoped>
.ui-comment {

}

.ui-comment-text {
  display: block;
  width: 100%;
  border: none;
  outline: none;
}

.ui-comment-text:focus {
  outline: 1px solid #7d7d7d;
}
</style>
