<template>
  <div>
    <h2 class="mb-16">{{$t('PaymentHistory.all_invoices')}}</h2>

    <table style="width:100%" class="card_table">
      <tr class="card_table_header">
        <td>{{$t('PaymentHistory.receipt')}}</td>
        <td>{{$t('PaymentHistory.pay_date')}}</td>
        <td>{{$t('PaymentHistory.amount')}}</td>
        <td>{{$t('PaymentHistory.level')}}</td>
        <td>{{$t('PaymentHistory.type')}}</td>
        <td>{{$t('PaymentHistory.mode')}}</td>
        <!--        <td>&nbsp;</td>-->
      </tr>


      <tr v-for="payment in student.allPayments" :key="payment.id">
        <td>#{{ payment.invoiceNumber }}</td>
        <td>{{ $dayjs(payment.createdAt).format('DD/MM/YYYY') }}</td>
        <td>{{ payment.levelPayments.paidAmount }}</td>
        <td>{{ payment.levelPayments.languageLevel }}</td>
        <td>{{ payment.paymentBy }}</td>
        <td> - </td>
        <!--        <td>-->
        <!--          <UiLink @click="findPayment(payment)">{{$t('PaymentHistory.view')}}</UiLink>-->
        <!--        </td>-->
      </tr>
    </table>
  </div>
</template>

<script>
import UiLink from "@/components/ui/UiLink";

export default {
  name: "PaymentHistory",
  components: {UiLink},
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // findPayment(payment) {
    //   this.$log(payment);
    // }
  }
}
</script>

<style scoped>
.card_table td {
  padding: 5px 0px;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}

.card_table_header td {
  font-size: 16px;
  line-height: 24px;
  color: #72849E;
}
</style>