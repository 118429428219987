<template>
  <div class="status-label" :style="cssAndText.css">
    {{ cssAndText.text }}
  </div>
</template>

<script>
export default {
  name: "StatusLabel",
  props: {
    value: {
      type: String,
      default: 'await'
    }
  },
  computed: {
    cssAndText() {
      let text = '';
      let color = '';
      let bgColor = '';
      if (this.value === 'await') {
        text = 'Await';
        color = '#FFBC42';
        bgColor = '#FFEECE';
      }
      if (this.value === 'booked') {
        text = 'Booked';
        color = '#59CD90';
        bgColor = '#D8F3E5';
      }
      if (this.value === 'paid') {
        text = 'Paid';
        color = '#59CD90';
        bgColor = '#D8F3E5';
      }
      return {css: {color: color, background: bgColor}, text}
    }
  }
}
</script>

<style scoped>
.status-label {
  display: inline-block;
  height: 32px;
  padding: 4px 12px;
  border-radius: 4px;

  font-size: 16px;
  line-height: 24px;

  margin-left: 12px;
}
</style>
