<template>
  <div v-if="status === true" class="prc_icon prc_icon_valid" title="PRC license has verified"></div>
  <div v-else-if="status === false" class="prc_icon prc_icon_invalid" title="PRC license hasn't verified"></div>
  <div v-else class="prc_icon prc_icon_undefined" title="PRC license is verifying"></div>
</template>

<script>
export default {
name: "UiPrcStatus",
  props: ['status'],
}
</script>

<style scoped>
.prc_icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: contain;
  /*background-repeat: no-repeat;*/
}
.prc_icon_valid {
  background-image: url("~@/assets/images/icons/prc_valid.png");
}
.prc_icon_invalid {
  background-image: url("~@/assets/images/icons/prc_invalid.png");
}
.prc_icon_undefined {
  background-image: url("~@/assets/images/icons/prc_undefined.png");
}
</style>