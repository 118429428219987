<template>
{{result}}
</template>

<script>
export default {
    name: "UiDepartaments",
    props: {
      departments: {},
    },
    computed: {
        result() {
            if (!this.departments || !this.departments.map) return '-';
            return this.departments.map((e) => e.name).join(", ");
        }
    }
}
</script>

<style scoped>

</style>
