<template>
  <div class="title mb-24">{{$t('StudentMotivation.motivation')}}</div>
  <div class="flex">
    <div class="col1">
      <div class="name">{{$t('StudentMotivation.why_germany')}}</div>
      <div class="value">{{ motivation.whyGermany }}</div>
      <div class="name">{{$t('StudentMotivation.what_qualities_do_you_have')}}</div>
      <div class="value">{{ motivation.yourQualities }}</div>
      <div class="name">{{$t('StudentMotivation.how_do_you_see_yourself')}}</div>
      <div class="value">{{ motivation.studyInPandemic }}</div>
    </div>
    <div class="col2">
      <div class="name">{{$t('StudentMotivation.confidence')}}</div>
      <div class="value">{{ motivation.howSerious }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StudentMotivation",
  props: {
    motivation: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: #272848;
}

.col1 {
  padding-right: 90px;

  .name {
    font-size: 16px;
    line-height: 24px;
    color: #7D7D7D;
  }

  .value {
    font-size: 16px;
    line-height: 24px;
    color: #383838;
    margin-bottom: 32px;
  }
}

.col2 {
  flex: 0 0 120px;

  .name {
    font-size: 14px;
    line-height: 22px;
    color: #7D7D7D;
  }

  .value {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #383838;
  }
}
</style>
