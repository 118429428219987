<template>
  <div class="undo-box" v-if="config.show">
    {{ config.text }} <a class="undo-link" @click="stop">{{$t('UndoMessage.undo')}}</a>
  </div>
</template>

<script>
export default {
  name: "UndoMessage",
  data() {
    return {
      config: {
        show: false,
        timer: false,
        text: 'You’ve just moved Sam Smith to the Payment.',
        stopFun: () => {
        },
      }
    }
  },
  methods: {
    async stop() {
      clearTimeout(this.config.timer)
      this.config.show = false;
      await this.config.stopFun();
    },
    start(text, student) {
      this.config.show = true;
      this.config.text = text;
      this.config.stopFun = async () => {
        try {
          const {data} = await this.$store.dispatch('student-statuses/find', {
            query: {
              studentId: student.id
            }
          });
          this.$log('find statuses:', data);

          let lastItem = data[data.length - 1];
          const result = await this.$store.dispatch('student-statuses/remove', lastItem.id);
          this.$log('delete status:', result);
          student.isDeleted = false;
          // TODO: update tab item counter up
        } catch (error) {
          this.$notify.parseFeathersErrors(error);
        }
      };
      setTimeout(() => {
        this.config.show = false;
      }, 7000);
    },
  },
  created() {
    this.$store.state.undo = this.start;
  }
}
</script>

<style scoped>
.undo-box {
  position: fixed;
  top: 20px;
  right: 20px;
  /*width: 400px;*/
  white-space: nowrap;
  height: 40px;
  background-color: #FFEECE;
  background-image: url("~@/assets/images/icons/exclamation.svg");
  background-repeat: no-repeat;
  background-position: 16px 11px;
  color: #383838;
  font-size: 14px;
  line-height: 22px;
  padding: 9px 16px 9px 40px;
  border-radius: 4px;
}

.undo-link {
  text-decoration: underline;
}
</style>