<template>
  <template v-for="page in pageList" :key="page">
    <span class="pg_dots" v-if="page === '...'">{{ page }}</span>
    <template v-else>
      <a-button class="pg_btn" v-if="page == currentPage" disabled>{{ page }}</a-button>
      <a-button class="pg_btn" v-else @click="$emit('openPage', page)">{{ page }}</a-button>
    </template>
  </template>
  &nbsp;&nbsp;
  <a-select class="pg_select" :value="`${pageSize}`" @change="$emit('setPageSize', $event)">
    <a-select-option value="15">15 / page</a-select-option>
    <a-select-option value="30">30 / page</a-select-option>
    <a-select-option value="60">60 / page</a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "UiPagination",
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    lastPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
  },
  emits: ['openPage', 'setPageSize'],
  computed: {
    pageList() {
      return this.pagenation2(this.currentPage, this.lastPage);
    }
  },
  methods: {
    // Implementation in ES6
    pagenation2(c, m) {
      let current = c,
          last = m,
          delta = 2,
          left = current - delta,
          right = current + delta + 1,
          range = [],
          rangeWithDots = [],
          l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
    setPage(page) {
      this.store.filters.page = page;
    }
  }
}
</script>

<style lang="scss">
.pg_btn:first-child {
  margin-left: 16px;
}

.pg_btn {
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 8px;
  color: #7D7D7D;
}

.pg_dots {
  margin-right: 8px;
}

.pg_select {
  width: 100px;

  .ant-select-selector {
    border-radius: 4px !important;
  }

  .ant-select-selection-item {
    color: #7D7D7D;
  }
}
</style>
