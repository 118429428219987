<template>
  <div class="ui-status">
    <div class="ui-status-icon" :class="{
            ui_status_red: icon === 'red',
            ui_status_yellow: icon === 'yellow',
            ui_status_green: icon === 'green',
        }"></div>
    <div class="ui-status-text">{{text}}</div>
  </div>
</template>

<script>
export default {
  name: "UiStatus",
  props: {
    student: {
      type: Object,
      required: true,
    }
  },
  computed: {
    lastStatus() {
      return this.student.lastStatusName;
    },

    calc() {
      // Interview
      if (this.lastStatus === 'interview_booked' ||
          this.lastStatus === 'interview_attended') return ['Booked', 'green'];
      if (this.lastStatus === 'interview_booking' ||
          this.lastStatus === 'interview_missed') return ['Await', 'yellow'];

      // Payment
      // Due Date passed
      if (this.lastStatus === 'choosing_payment') {
        if (this.$dayjs(this.student.lastStatus.createdAt)
                .add(5, 'day')
                .isBefore(this.$dayjs())
        ) return ['Delay', 'red'];
        else
          return ['Await', 'yellow'];
      }

      if (this.lastStatus === 'payment_by_card' ||
          this.lastStatus === 'payment_by_wire_transfer' ||
          this.lastStatus === 'payment_confirming') return ['Verify','green'];
      return ['-',''];
    },
    text() {
      return this.calc[0];
    },
    icon() {
      return this.calc[1];
    },
  }
}
</script>

<style scoped>
.ui-status {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.ui-status-icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0px 8px;
}

.ui_status_red {
  background: #FF5964;
}

.ui_status_yellow {
  background: #FFBC42;
}

.ui_status_green {
  background: #59CD90;
}
</style>
