<template>
    <div class="star" :class="{active:isFavorite}" @click.stop="update"></div>
</template>

<script>
export default {
    name: "UiStar",
    props: {
        user: {}
    },
    computed: {
        isFavorite() {
            return this.user?.detail?.isFavorite
        }
    },
    methods: {
        async update() {
            this.$emit('update');
            if (this.user?.detail && this.user?.detail.id) {
                try {
                    const values = {...this.user.detail};
                    values.isFavorite = !values.isFavorite;
                    await this.$store.dispatch('students-details/patch', [values.id, values]);
                    // eslint-disable-next-line vue/no-mutating-props
                    this.user.detail = values;
                } catch (error) {
                    this.$notify.parseFeathersErrors(error);
                }
            }
        }
    }
}
</script>

<style scoped>
.star {
    display: inline-block;
    width: 24px;
    height: 24px;
    -webkit-mask-image: url("~@/assets/images/star.svg");
    mask-image: url("~@/assets/images/star.svg");
    background-color: #BFBFBF;
    cursor: pointer;
}

.active {
    background-color: #FFBC42;
}
</style>
