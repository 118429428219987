<template>
  <div class="history-title mb-16">{{$t('History.history')}}</div>
  <div class="card-history">

    <div class="card-history-item" v-for="(item, idx) in statusesRev" :key="idx">
      <div class="item-circle"></div>
      <div class="item-content">
        {{ $t('student_status.' + item.status) }}
        <div v-if="item.comment" class="status_comment">{{item.comment}}</div>
        <div class="date">{{ $dayjs(item.createdAt).format('DD-MM-YYYY, hh:mm') }}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "History",
  props: {
    student: {
      type: Object,
      required: true,
    }
  },
  computed: {
    statusesRev() {
      return this.student.statusesRev;
    }
  }
}
</script>

<style scoped lang="scss">
.history-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #272848;
}

.card-history {
  display: flex;
  flex-direction: column;
}

.card-history-item {
  border-left: 1px solid #D9D9D9;
  padding: 0px 10px 16px 16px;
}

.card-history-item:last-child {
  border-left: 1px solid transparent;
}

.item-circle {
  position: relative;
  left: -27px;
  display: block;
  width: 21px;
  height: 21px;
  background: #258FFB;
  border: 4px solid #ffffff;
  border-radius: 50%;
}

.item-content {
  position: relative;
  top: -20px;

  font-size: 16px;
  line-height: 24px;
  color: #383838;

  .date {
    font-size: 14px;
    line-height: 22px;
    color: #7D7D7D;
  }
}


.status_comment {
  font-style: italic;
  font-size: 92%;
}
.status_comment::before {
  content: "«";
}
.status_comment::after {
  content: "»";
}
</style>
