<template>
  <h2 class="mb-16">{{$t('MissedReason.missed_reason')}} <img src="@/assets/images/card/edit.svg" @click="edit"></h2>
  <div class="flex mb-16">
    <div class="label">{{$t('MissedReason.reason')}}</div>
    <div class="value"><UiComment v-model="lastStatus.newComment"></UiComment></div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props,vue/no-side-effects-in-computed-properties */
import UiComment from "@/components/ui/UiComment";
export default {
name: "MissedReason",
  components: {UiComment},
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lastStatus() {
      this.student.lastStatus.newComment = this.student.lastStatus.comment || '';
      return this.student.lastStatus;
    }
  },
  methods: {
    edit() {
      this.student.isChanged = true;
    }
  }
}
</script>

<style scoped>
.label {
  flex-basis: 123px;
  flex-grow: 0;
  flex-shrink: 0;

  font-size: 16px;
  line-height: 24px;
  color: #7D7D7D;
}

.value {
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
  padding-right: 32px;
}
</style>
