<template>
  <div class="flex flex-vertical-center mb-16">
    <h2>{{$t('InterviewDetails.interview_details')}}</h2>
    <StatusLabel v-if="
      student.lastStatusName === 'interview_booked' ||
      student.lastStatusName === 'interview_attended'" value="booked"/>
    <StatusLabel v-else value="await"/>
  </div>

  <template v-if="student.lastStatusName === 'interview_booked' || student.lastStatusName === 'interview_attended'">
    <div class="flex mb-16">
      <div class="label">{{$t('InterviewDetails.date')}}</div>
      <div class="value">{{ $dayjs(student?.lastInterview?.timeslot?.startDate).format('MMM D, YYYY on hh:mm, ddd') }}</div>
    </div>
<!--    <div class="flex mb-16">-->
<!--      <div class="label">{{$t('InterviewDetails.link')}}</div>-->
<!--      <div class="value">calendly.com/s/lRHfLAlX</div>-->
<!--    </div>-->

    <div class="flex mb-16">
      <div class="label">{{$t('InterviewDetails.missed')}}</div>
      <div class="value">{{ student.missedCount }}</div>
    </div>

    <div class="flex flex-vertical-center mb-32">
      <div class="label">{{$t('InterviewDetails.attendance')}}</div>
      <div class="value">
        <a-radio-group class="rg-attendance"
                       :defaultValue="student.attendance"
                       :value="student.attendance"
                       @change="setAttendance">
          <a-radio-button value="attended" :disabled="student.tempStatusName=='interview_missed'">
            {{$t('InterviewDetails.attended')}}
          </a-radio-button>
          <a-radio-button value="missed" :disabled="student.tempStatusName=='interview_attended'">
            {{$t('InterviewDetails.missed')}}
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>

    <div class="flex flex-vertical-center mb-32" v-if="student.attendance == 'missed'">
      <div class="label">{{$t('InterviewDetails.allow_rebook')}}</div>
      <div class="value">
        <a-radio-group class="rg-attendance"
                       :defaultValue="student.allowRebook"
                       :value="student.allowRebook"
                       @change="setAllowRebook"
        >
          <a-radio-button value="1">
            {{$t('InterviewDetails.yes')}}
          </a-radio-button>
          <a-radio-button value="0">
            {{$t('InterviewDetails.no')}}
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>

  </template>

  <template v-else>
    <div class="flex mb-16">
      <div class="label">{{$t('InterviewDetails.shift_date')}}</div>
      <div class="value">{{ $dayjs(student.lastStatus.createdAt).format('MMM D, YYYY on hh:mm, ddd') }}</div>
    </div>
    <div class="flex mb-16">
      <div class="label">{{$t('InterviewDetails.comment')}}</div>
      <div class="value">{{ student.lastStatus.comment }}</div>
    </div>
  </template>

</template>

<script>
/* eslint-disable vue/no-mutating-props */
import StatusLabel from "@/components/students/card/StatusLabel";

export default {
  name: "InterviewDetails",
  components: {StatusLabel},
  props: {
    student: {
      type: Object,
      required: true,
    },
    pushStatus: {
      type: Function,
      required: true,
    },
  },
  methods: {

    bindSaveCancel() {

      console.log('on save binding done!')

      this.student.onSave = async () => {
        console.log('onSave');
        this.student.isChanged = false;

        // save comments | rejection info
        if (this.student.lastStatusName === 'interview_booked' ||
            this.student.lastStatusName === 'interview_attended' ||
            this.student.lastStatusName === 'interview_missed') {
          try {
            await this.$store.dispatch('students-details/patch', [
              this.student.detail.id, {
                comments: this.student.detail.comments || {},
                confirmedLanguageLevel: this.student.confirmedLanguageLevel
              }
            ]);
            await this.$store.dispatch('students/patch', [
              this.student.id, {
                confirmedLanguageLevel: this.student.confirmedLanguageLevel
              }
            ]);
            console.log("comment saved!");
          } catch (error) {
            console.log(error);
            this.$notify.parseFeathersErrors(error);
          }
        }

        // save attended
        if (this.student.lastStatusName === 'interview_booked' && this.student.attendance === 'attended') {
          try {
            console.log('try try');
            await this.pushStatus({
              status: 'interview_attended',
              // studentId: this.student.id,
              withoutNextCard:true
            });
            this.student.tempStatusName = 'interview_attended';
          } catch (error) {
            console.log(error);
            this.$notify.parseFeathersErrors(error);
          }
        }

        // save missed
        if (this.student.lastStatusName === 'interview_booked' && this.student.attendance === 'missed') {
          console.log("Save missed");
          try {
            await this.pushStatus({
              status: 'interview_missed',
              comment: this.student.lastStatus.newComment,
              // studentId: this.student.id,
              withoutNextCard: true
            });

            if (this.student.allowRebook == '1') {
              await this.pushStatus({
                status: 'interview_booking',
                comment: this.student.lastStatus.newComment,
                // studentId: this.student.id,
              });
            }

            //this.$emit('reloadTab');
            // this.student.tempStatusName = 'interview_missed';
          } catch (error) {
            this.$notify.parseFeathersErrors(error);
          }
        }


      }

      // cancel changes
      this.student.onCancel = function () {
        console.log('onCancel');
        if (this.lastStatusName === 'interview_booked') {
          this.attendance = '';
          if (this.student.detail?.comments?.interviewAttended) {
            this.student.detail.comments.interviewAttended = {};
          }
          if (this.student.detail?.comments?.interviewMissed) {
            this.student.detail.comments.interviewMissed = {};
          }
          // TODO: for cancel comment editing? use backup old comment on data enrichment
        }
      }
    },
    setAttendance($event) {
      this.student.attendance = $event.target.value;
      this.student.isChanged = true;

      // TODO: optimize this
      this.bindSaveCancel();
    },
    setAllowRebook($event) {
      this.student.allowRebook = $event.target.value;
      this.student.isChanged = true;

      // TODO: optimize this
      this.bindSaveCancel();
    },
  },
  mounted() {
    // TODO: optimize this
    this.bindSaveCancel();
  }
}
</script>

<style scoped lang="scss">
.label {
  flex-basis: 123px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 24px;
  color: #7D7D7D;
}

.value {
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}

.rg-attendance {
  .ant-radio-button-wrapper {
    width: 128px;
    text-align: center;
  }
}

</style>
