
function createStatusTitle(statusName) {
    return ('_' + statusName).replace(
        /(_\w)/g,
        function (value) {
            return value.toUpperCase().replace('_', ' ');
        }
    )
}


function statusSection(status) {
    if (status.indexOf('review') !== -1) return 'review';
    if (status.indexOf('interview') !== -1) return 'interview';
    if (status.indexOf('payment') !== -1) return 'payment';
    return '';
}

function enrichStudent(student) {
    // Fix for pre-seeds
    if (student?.student?.id) {
        const user = student;
        student = student.student;
        delete user.student;
        student.user = user;
    }

    if (!student.email) {
        student.email = student?.user?.email;
    }

    student.detail = student.detail || {}
    student.cv = student?.user?.cv || {}
    student.cv.name = student?.user?.cv?.name || '-'

    // fix status list
    let statuses = [student.statuses];
    if (student.statuses instanceof Array) {
        statuses = student.statuses
    } else {
        statuses = [];
    }

    // missed interviews
    //console.log(student);
    student.missedCount = statuses.filter((el) => el.status === 'interview_missed').length;



    // sort statuses
    student.statusesRev = statuses.sort((a, b) => {
        if (a.createdAt < b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        return 0;
    });

    // const statusesDirect = statuses.sort((a, b) => {
    //     if (a.createdAt < b.createdAt) return -1;
    //     if (a.createdAt > b.createdAt) return 1;
    //     return 0;
    // });

    // calc shift date
    let oldSection = '';
    let oldItem = {};
    for (let i = 0; i < student.statusesRev.length; i++) {
        let statusItem = student.statusesRev[i];
        let section = statusSection(statusItem.status);
        //console.log(oldSection, section);
        if (oldSection === 'interview' && section === 'review') {
            student.interviewBookingDate = oldItem.createdAt;
            break;
        }
        oldSection = section;
        oldItem = statusItem;
    }
    //console.log('---------------');

    student.lastStatus = (student.statusesRev[0]) ? student.statusesRev[0] : {};
    student.lastStatusName = student.lastStatus.status;
    student.tempStatusName = student.lastStatusName; // TODO: remove it
    student.lastStatusTitle = createStatusTitle(student.lastStatusName);

    // last interview
    const n = student?.user?.interviews?.length;
    student.lastInterview = n ? student.user.interviews[n - 1] : {};
    const durationInMinutes = student?.lastInterview?.timeslot?.durationInMinutes;
    student.lastInterviewHours = durationInMinutes ? durationInMinutes / 60 : '-';

    // Attendance info
    student.attendance = '-';

    if (student.lastStatusName === 'interview_attended') {
        student.attendance = 'attended';
    }
    if (student.lastStatusName === 'interview_missed') {
        student.attendance = 'missed';
    }

    if (!student.allowRebook) {
        student.allowRebook = '1';
    }



    // watch for changed
    student.panelName = '';
    student.showPanel = false;
    student.isChanged = false;

    student.onSave = () => {
        console.log('onSave');
        this.student.isChanged = false;
    }
    student.onCancel = () => {
        console.log('onCancel');
        this.student.isChanged = false;
    }

    // if (student.user.photo?.key && student.user.photo.key.startsWith('http://')) {
    //     student.user.photo.key = null;
    // }

    student.currentPayment = {};

    student.isRejected = [
        'review_rejected',
        'interview_rejected',
        'payment_rejected',
    ].includes(student.lastStatusName)

    student.prevStatusName = '';
    student.prevStatusTitle = '';
    if (student.statusesRev.length > 1) {
        student.prevStatusName = student.statusesRev[1].status;
        student.prevStatusTitle = createStatusTitle(student.prevStatusName);
    }


    if (student?.detail?.hasAllowedProfession) {
        if (!student.detail.currentProfession) {
            student.detail.currentProfession = 'Nurse or midwife';
        }
    }

    return student;
}

function enrichStudents(students) {
    return students.map(enrichStudent);
}

export { enrichStudent, enrichStudents };