import { paramsForServer } from 'feathers-hooks-common'

export default class DataSource {
  constructor(
    vueInst,
    { service, params, filters, id, statusList, enrichFunction }
  ) {
    this.id = id
    this.service = service
    this.params = params || {}
    this.filters = filters || {}
    this.statusList = statusList
    this.total = 0
    this.limit = 100
    this.skip = 0
    this.pageSize = 60
    this.currentPage = 1
    this.lastPage = 1

    this.sortColumn = null
    this.sortDirect = null

    this.items = []
    this.currentItem = false

    this.vueInst = vueInst

    this.enrichFunction = enrichFunction
  }
  loadAll() {
    //
  }
  async sortBy(column) {
    if (this.sortColumn !== column) {
      this.sortColumn = column
      this.sortDirect = null
    }
    if (!this.sortDirect) this.sortDirect = 1
    else if (this.sortDirect == 1) this.sortDirect = -1
    else if (this.sortDirect == -1) this.sortDirect = null
    await this.reloadAll()
  }
  async reloadAll() {
    if (!this.params.query) {
      this.params.query = {}
    }

    Object.assign(this.params.query, {
      $limit: this.pageSize,
      $skip: this.pageSize * (this.currentPage - 1),
    })

    let sequelizeQuery = false

    if (this.sortDirect === 1 || this.sortDirect === -1) {
      if (this.sortColumn.indexOf('.') !== -1) {
        // not implemented
        // const direct = (this.sortDirect == 1) ? 'ASC' : 'DESC';
        // sequelizeQuery = {
        //     include: [{
        //         model: '$user',
        //         include: [{
        //             model: '$interviews',
        //             include: [{
        //                 model: '$timeslots'
        //             }],
        //             order: [[ 'createdAt', 'DESC' ]],
        //         }],
        //     }],
        //     order: [['$user', '$interviews', '$timeslots', 'startDate', direct]],
        // }
      } else {
        const sort = {}
        sort[this.sortColumn] = this.sortDirect
        this.params.query.$sort = sort
      }
    }

    let path = this.service + '/find'
    if (sequelizeQuery !== false) {
      this.params.sequelizeQuery = sequelizeQuery
    }

    // FILTERS
    // if (this.currentTab.isFavorite) {
    //     // query.query
    // }
    if (this.statusList) {
      this.params.query.status = {
        $in: this.statusList,
      }
    }

    this.params = paramsForServer(this.params)

    const response = await this.vueInst.$store.dispatch(path, this.params)

    this.total = response.total
    this.limit = response.limit
    this.skip = response.skip

    this.currentPage = this.skip / this.pageSize + 1
    this.lastPage = Math.ceil(this.total / this.pageSize)

    if (this.enrichFunction) {
      response.data = this.enrichFunction(response.data) // Data enrichment
    }
    this.items = response.data
    this.vueInst.$log(response)
  }
  reloadItem() {
    //
  }
  openPage() {}
  hideItem() {}
  showItem() {}
  moveItemToAnotherDS() {}
  selectItem() {
    //
  }
  selectNextItem() {
    //
  }
  pushStatus() {}
}
