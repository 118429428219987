<template>
  <div class="aps-body flex">
    <div class="aps-table-wrap">
      <div class="aps-table" :class="{isCardOpened:isCardOpened}">
        <div class="aps-table-head">
          <div class="title2">{{ options.description }}</div>
        </div>
        <component
            :is="options.component"
            :items="itemsFiltered"
            :currentItemId="currentItemId"
            @select="openCard"
            :ds="ds"
        ></component>
      </div>
      <div :class="{isCardOpened:isCardOpened}" class="padding-wrap">
        <div :class="{isCardOpened:isCardOpened}" class="pagination flex mb-16">
          <div>
            <UiPagination
                :currentPage="parseInt(ds.currentPage)"
                :lastPage="ds.lastPage"
                :pageSize="ds.pageSize"
                @openPage="openPage"
                @setPageSize="setPageSize"
            ></UiPagination>
          </div>
          <div v-if="ds.items" class="flex-right pr-24">
            {{ ds.skip + 1 }} - {{ ds.skip + itemsFiltered.length }} of {{ ds.total }} items
          </div>
        </div>

      </div>
    </div>

    <div class="aps-card" :class="{isCardOpened:isCardOpened}">
      <transition name="fade" mode="out-in">
        <TableCard v-if="student" :student="student" :key="student.id"
                   @hideCard="hideCard"
                   @nextCard="nextCard"
                   @reloadTab="ds.reloadAll"
                   :tabNames="options.cardTabs"
        ></TableCard>
      </transition>
    </div>

  </div>
  <UndoMessage></UndoMessage>
</template>

<script>
import DataSource from "@/utils/DataSource";
import Review from "@/components/students/tables/applicants/Review";
import {enrichStudents} from "@/utils/dataEnriching";
import TableCard from "@/components/students/card/TableCard";
import UiPagination from "@/components/ui/UiPagination";
import UndoMessage from "@/components/UndoMessage";

export default {
  name: "StudentTableWithCard",
  components: {UndoMessage, UiPagination, TableCard, Review},
  props: {
    options: {},
    preOpen: {},
    openable: {
      type: Boolean,
      default: true,
    }
  },
  data() {

    // get DS from state
    let ds = false;
    if (this.options.name && this.options.id) {
      const name = this.options.name + this.options.id;
      ds = this.$store.state.dsList[name];
    }

    return {
      ds: ds || new DataSource(this, {
        id: 1,
        name: 'review',
        title: 'Review',
        description: 'Application review',
        service: 'students',
        statuses: ['review_started'],
        enrichFunction: enrichStudents,
        cardTabs: ['applicant_info'],
        count: 0,
        ...this.options,
      }),
      isCardOpened: false,
      currentItemId: false,
      currentPageSize: localStorage.getItem('pageSize') * 1 || 15,
    }
  },
  computed: {
    student() {
      return this.itemsFiltered.find((_student) => _student.id === this.currentItemId)
    },
    itemsFiltered() {
      return this.ds.items.filter((el) => !el.isDeleted)
    },
  },
  methods: {
    setPageSize(pageSize) {
      localStorage.setItem('pageSize', pageSize)
      this.ds.pageSize = pageSize * 1;
      this.ds.reloadAll();
    },
    async openPage(page) {
      this.ds.currentPage = page;

      // pre open page size
      if (this.preOpen.pageSize) {
        this.ds.pageSize = this.preOpen.pageSize;
        delete this.preOpen.pageSize;
      }

      // pre open page number
      if (this.preOpen.pageNum) {
        this.ds.currentPage = this.preOpen.pageNum;
        delete this.preOpen.pageNum;
      } else {
        this.$route.params = {};
        this.$route.params.tabName = this.options.name;
        this.$route.params.pageNum = this.ds.currentPage;
        this.$route.params.pageSize = this.ds.pageSize;
        this.$saveRouteParams();
      }

      await this.ds.reloadAll();

      // pre open card
      if (this.preOpen.studentId && this.ds.items) {
        const found = this.itemsFiltered.find((s) => s.id === this.preOpen.studentId);
        console.log(found);
        if (found) this.openCard(found);
        delete this.preOpen.studentId;
      }

    },
    openCard(item) {
      if (!this.openable) return;

      this.currentItemId = item.id;
      this.isCardOpened = true;
      this.$route.params.tabName = this.options.name;
      this.$route.params.pageNum = this.ds.currentPage;
      this.$route.params.pageSize = this.ds.pageSize;
      this.$route.params.studentId = item.id;
      this.$saveRouteParams();
    },
    hideCard() {
      this.isCardOpened = false;
      delete this.$route.params.studentId;
      this.$saveRouteParams();
    },

    nextCard() {
      let index = this.itemsFiltered.findIndex((_student) => _student === this.student);
      console.log('index', index)

      const oldStudent = this.student;

      // show next card
      if (index !== -1 && index < this.itemsFiltered.length - 1) {
        this.currentItemId = this.itemsFiltered[index + 1].id;
      } else {
        this.isCardOpened = false;
      }

      // delete current row
      try {
        // this.$store.state.applicants[this.currentTab.id].data.splice(index, 1);
        oldStudent.isDeleted = true;
        this.$log('hide', oldStudent);
        // this.currentTab.count--;
      } catch (e) {
        console.log(e);
      }

    },

  },
  created() {
    // save DS to state
    if (this.options.name && this.options.id) {
      const name = this.options.name + this.options.id;
      this.$store.state.dsList[name] = this.ds;
    }
  },
  async mounted() {
    await this.openPage(1);
  }
}
</script>

<style scoped>

</style>